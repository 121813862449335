import React, { FC } from "react";
import * as styles from "./BenefitsComponent.module.scss";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import cx from "classnames";
import CustomButton from "../CustomButton/CustomButton";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";
import translate from "../../../i18n/translate";

const BenefitsComponent: FC<any> = ({
  data,
  lineClass,
  btn,
  mainDivStyle,
  isCareer,
  buttonText = translate("careers-apply"),
  linkPath = "https://globaldatanet.jobs.personio.de/",
}) => {
  const width = UseWindowSize();

  return (
    <InnerWrapper>
      <div className={cx(styles.development, mainDivStyle)}>
        <div className={styles.benefits}>
          <div className={styles.benefitsHeader}>
            {isCareer && width.width > 1200 ? null : (
              <>
                <h2 className={styles.head}>{data?.heading}</h2>
                {btn ? (
                  <CustomButton
                    text={buttonText}
                    linkPath={linkPath}
                    image={arrowRightWhite}
                  />
                ) : null}
              </>
            )}
            <br />
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.25}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
          </div>
          <div className={styles.applicationMap}>
            {isCareer && width.width > 1200 ? (
              <div className={styles.careerBenefits}>
                <h2 className={cx(styles.head, styles.careerHead)}>
                  {data?.heading}
                </h2>
                {btn ? (
                  <div className={styles.apply}>
                    <CustomButton
                      text={buttonText}
                      linkPath={linkPath}
                      image={arrowRightWhite}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className={styles.flowContainer}>
              {data?.benefitsDataMobile?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={
                      index % 2
                        ? lineClass
                          ? styles.flowEvenContainerDiv
                          : styles.flowOddContainerDiv
                        : lineClass
                        ? styles.flowOddContainerDiv
                        : styles.flowEvenContainerDiv
                    }
                  >
                    <div className={styles.flowItemContainer}>
                      <div className={styles.verticalLine}>
                        <div className={styles.flowStop}></div>
                      </div>
                      <div className={styles.flowItemDay}>
                        <h5>{item?.count}</h5>
                        {item?.countPara ? <p>{item?.countPara}</p> : null}
                      </div>
                      <div className={cx(styles.flowItem, lineClass)}>
                        <div className={styles.flowItemHeading}>
                          {item?.img ? (
                            <img
                              src={item?.img}
                              alt={item.alt}
                              className={styles.yourBenefitsIcon}
                            />
                          ) : null}
                          <h4>{item?.heading}</h4>
                        </div>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.25}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
          </div>
        </div>
      </div>
    </InnerWrapper>
  );
};

export default BenefitsComponent;
