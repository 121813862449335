import React from "react";
import CareersMain from "../components/CareersUiComponents/CareersMain";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";
import headerimg from "../images/creatorHeader.jpg";

const Careers = () => {
  return (
    <Layout location="/blog">
      <SEO
        title="Careers"
        description="Are you looking for a new challenge? See our open positions, apply and be part of our journey."
        type="website"
        image={headerimg}
      />
      <CareersMain />
    </Layout>
  );
};

export default Careers;
