import React, { FC } from "react";
import * as styles from "./CareersMain.module.scss";
import tableDesktop from "../../images/creatorHeader.jpg";
import tableDesktopMobile from "../../images/mobile-images/creatorHeader.webp";
import { UseWindowSize } from "../hooks/UseWindowSize";
import { ModalProps } from "../Shared/MobileModal/MobileModal";
import manThinking from "../../images/manThinking.jpg";
import inNumbersImage from "../../images/inNumbersImage.jpg";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import { FaInstagram } from "react-icons/fa";
import shot1 from "../../images/shot1.jpg";
import shot2 from "../../images/shot2.jpg";
import shot3 from "../../images/shot3.jpg";
import shot4 from "../../images/shot4.jpg";
import shot5 from "../../images/shot5.jpg";
import shot6 from "../../images/shot6.jpg";
import shot7 from "../../images/shot7.jpg";
import shot8 from "../../images/shot8.jpg";
import shot9 from "../../images/shot9.jpg";
import shot10 from "../../images/shot10.jpg";
import shot11 from "../../images/shot11.jpg";
import shot12 from "../../images/shot12.jpg";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import {
  coreValuesData,
  yourBenefitData,
  inNumbersData,
  TwoColumnBannerData,
  BenefitData,
} from "./CareersMain.helper";
import TwoColumnBanner from "../Shared/TwoColumnImgTextbanner/TwoColumnBanner";
import BenefitsComponent from "../Shared/BenefitsComponent/BenefitsComponent";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import translate from "../../i18n/translate";
import { AUTHOR_SOCIAL_ICON_COLOR_WHITE } from "../AuthorsUiComponents/AuthorIntro/AuthorIntro.helper";

const CareersMain: FC<ModalProps> = () => {
  const width = UseWindowSize();

  return (
    <div className={styles.careers}>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("careers-title")}
          description={translate("careers-description")}
          homeBackground={tableDesktopMobile}
          buttonText={translate("careers-apply")}
          date=""
          link="https://globaldatanet.jobs.personio.de/"
          isExternalLink
          alt="Become a creator"
        />
      ) : (
        <HeroBanner
          heading={translate("careers-title")}
          description={translate("careers-description")}
          homeBackground={tableDesktop}
          buttonText={translate("careers-apply")}
          date=""
          link="https://globaldatanet.jobs.personio.de/"
          isExternalLink
        />
      )}

      <InnerWrapper>
        <div className={styles.banner}>
          <TwoColumnBanner data={TwoColumnBannerData} />
        </div>
        <div className={styles.coreValue}>
          <div className={styles.coreValueColumbRight}>
            <h2 className="heading">
              <div className={styles.heading}>
                <span>.</span>
                {translate("careers-coreValue")}
              </div>
            </h2>
            <div className={styles.coreValueItemBox}>
              {coreValuesData?.map(item => {
                return (
                  <div className={styles.coreValueItem} key={item.count}>
                    <h5>{item?.count}</h5>
                    <p>{item?.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.coreValueColumbLeft}>
            <img
              src={manThinking}
              alt="Skycrapers in city surrounded by smaller buildings as representation of core values at globaldatanet"
              className={styles.keyTopicsImage}
            />
          </div>
        </div>

        <div className={styles.yourBenefits}>
          <h2 className="heading">
            <span>.</span>
            {translate("careers-benefits")}
          </h2>
          <div className={styles.yourBenefitsInner}>
            {yourBenefitData.map((item, index) => {
              return (
                <div className={styles.yourBenefitsItem} key={index}>
                  <img
                    src={item?.image}
                    alt="culture"
                    className={styles.yourBenefitsIcon}
                  />
                  <h6 className="heading">
                    <span>.</span>
                    {item?.heading}
                  </h6>
                  <ul>
                    {item?.values.map((item1, index1) => {
                      return <li key={index1}>{item1}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <CircleBlur
            initialScale={0.1}
            initialOpacity={0}
            animateScale={1.8}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleLeft}
          />
          <CircleOutline
            isBlurVisible={true}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundCircleLeft}
          />
        </div>
        <div className={styles.innumbers}>
          <div className={styles.coreValue}>
            <div className={styles.inNumberColumbLeft}>
              <img
                src={inNumbersImage}
                alt="Densely populated city from above to show various aspects of globaldatanet and our team"
                className={styles.keyTopicsImage}
              />
            </div>
            <div className={styles.inNumberColumbRight}>
              <h2 className="heading">
                <span>.</span>
                {translate("careers-inNumbers")}
              </h2>
              <div className={styles.coreValueItemBox}>
                {inNumbersData?.map(item => {
                  return (
                    <div className={styles.inNumbersItem} key={item.count}>
                      <h5>{item?.count}</h5>
                      <p>{item?.description}</p>
                    </div>
                  );
                })}
              </div>
              <CircleOutline
                isBlurVisible={true}
                initialScale={0.3}
                animateScale={1}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundCircleLeft}
              />
            </div>
          </div>
        </div>

        <div className={styles.insights}>
          <h2 className="heading">
            <span>.</span>
            {translate("careers-insights")}
          </h2>
          <div>
            <div className={styles.followUs}>
              <FaInstagram color={AUTHOR_SOCIAL_ICON_COLOR_WHITE} size={20} />
              <p>
                Follow us on insta
                <a
                  href="https://www.instagram.com/globaldatanet/"
                  target="_blank"
                  rel="noreferrer"
                >
                  @globaldatanet
                </a>
              </p>
            </div>
            <div className={styles.instaContainer}>
              <div className={styles.instaLeftBox}>
                <div className={styles.igImageGroup1}>
                  <img
                    src={shot1}
                    alt="igImage"
                    className={styles.igImageGroup1Item}
                  />
                  <img
                    src={shot2}
                    alt="igImage"
                    className={styles.igImageGroup1Item}
                  />
                  <img
                    src={shot3}
                    alt="igImage"
                    className={styles.igImageGroup1Item}
                  />
                </div>
                <div className={styles.igImageGroup2}>
                  <img
                    src={shot8}
                    alt="igImage"
                    className={styles.igImageGroup2Item}
                  />
                  <div className={styles.igImageGroup2Btm}>
                    <img
                      src={shot5}
                      alt="igImage"
                      className={styles.igImageGroup2BtmItem}
                    />
                    <img
                      src={shot6}
                      alt="igImage"
                      className={styles.igImageGroup2BtmItem}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.instaRightBox}>
                <div className={styles.igImageGroup1}>
                  <img
                    src={shot7}
                    alt="igImage"
                    className={styles.igImageGroup1Item}
                  />
                  <img
                    src={shot4}
                    alt="igImage"
                    className={styles.igImageGroup1Item}
                  />
                  <img
                    src={shot9}
                    alt="igImage"
                    className={styles.igImageGroup1Item}
                  />
                </div>
                <div className={styles.igImageGroup2}>
                  <div className={styles.igImageGroup2Btm}>
                    <img
                      src={shot10}
                      alt="igImage"
                      className={styles.igImageGroup2BtmItem}
                    />
                    <img
                      src={shot11}
                      alt="igImage"
                      className={styles.igImageGroup2BtmItem}
                    />
                  </div>
                  <img
                    src={shot12}
                    alt="igImage"
                    className={styles.igImageGroup2Item}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.benefits}>
          <BenefitsComponent data={BenefitData} btn lineClass={""} isCareer />
        </div>
      </InnerWrapper>
    </div>
  );
};

export default CareersMain;
