import cultureIcon from "../../../static/images/icon/social_media.svg";
import communityIcon from "../../../static/images/icon/community.svg";
import workLikeIcon from "../../../static/images/icon/group_510.svg";
import gdnPromote from "../../images/team.jpg";
import translate from "../../i18n/translate";

export const coreValues = [
  {
    count: "01",
    heading: ".Love what you do",
    description:
      "Talent without passion is like having a Lambda function without any argument.",
  },
  {
    count: "02",
    heading: ".Learn and be curious",
    description:
      "How to get better? Motivation, curiosity, passion and striving for new technologies. That's all you need!.",
  },
  {
    count: "03",
    heading: ".People first",
    description:
      "The team is the most important resource. Care for each other, support each other and have fun together.",
  },
  {
    count: "04",
    heading: ".Trust",
    description:
      "Longstanding partnerships are based on trust, transparency and honesty. This is what we value.",
  },
  {
    count: "05",
    heading: ".Invent & Simplify",
    description:
      "Nothing worse than going out with an over engineered app. We love to invent innovative solutions while keeping things simple and efficient.",
  },
  {
    count: "06",
    heading: ".Crazy Ideas & Creativity",
    description:
      "Run of the mill? Not in our company! We think differently and like crazy ideas, creating something special and unique.",
  },
];

export const yourBenefitData = [
  {
    image: cultureIcon,
    heading: translate("careers-benefitsHeading1"),
    values: [
      translate("careers-culture1"),
      translate("careers-culture2"),
      translate("careers-culture3"),
      translate("careers-culture4"),
      translate("careers-culture5"),
      translate("careers-culture6"),
      translate("careers-culture7"),
    ],
  },
  {
    image: communityIcon,
    heading: translate("careers-benefitsHeading2"),
    values: [
      translate("careers-community1"),
      translate("careers-community2"),
      translate("careers-community3"),
      translate("careers-community4"),
      translate("careers-community5"),
      translate("careers-community6"),
      translate("careers-community7"),
    ],
  },
  {
    image: workLikeIcon,
    heading: translate("careers-benefitsHeading3"),
    values: [
      translate("careers-workLife1"),
      translate("careers-workLife2"),
      translate("careers-workLife3"),
      translate("careers-workLife4"),
      translate("careers-workLife5"),
      translate("careers-workLife6"),
    ],
  },
];

export const CareerMainData = {
  heading1: translate("careers-thatUsHeading"),
  paragraph1: translate("careers-thatUsDesc"),
  heading2: `.Behind the scenes, the drivers behind`,
  paragraph2: `The story behind our innovative cloud solutions begins with
  our team. We are a team of versatile specialists not
  generalists. Everyone focuses on their core areas building up
  in depth expert knowledge, ensuring highest quality and deep
  expertise in a variety of areas.`,
};

export const coreValuesData = [
  { count: "01", description: translate("careers-coreValue1") },
  { count: "02", description: translate("careers-coreValue2") },
  { count: "03", description: translate("careers-coreValue3") },
  { count: "04", description: translate("careers-coreValue4") },
  { count: "05", description: translate("careers-coreValue5") },
  { count: "06", description: translate("careers-coreValue6") },
  { count: "07", description: translate("careers-coreValue7") },
  { count: "08", description: translate("careers-coreValue8") },
  { count: "09", description: translate("careers-coreValue9") },
];

export const inNumbersData = [
  { count: "31", description: translate("careers-inNunbers1") },
  { count: "2", description: translate("careers-inNunbers2") },
  { count: "95%", description: translate("careers-inNunbers3") },
  { count: "0", description: translate("careers-inNunbers5") },
  { count: "2", description: translate("careers-inNunbers6") },
  { count: "13", description: translate("careers-inNunbers7") },
  { count: "51", description: translate("careers-inNunbers8") },
  { count: "2", description: "HashiCorp Ambassadors" },
  { count: "1", description: "AWS Ambassador" },
  { count: "2", description: "AWS Competencies" },
];

export const TwoColumnBannerData = {
  heading: CareerMainData.heading1,
  details: CareerMainData.paragraph1,
  img: gdnPromote,
  alt: "globaldatanet team at the rooftop on the hamburg office ",
};

export const BenefitData = {
  heading: translate("careers-process"),
  benefitsDataMobile: [
    {
      count: "01",
      countPara: "Day 1",
      heading: translate("careers-processHeading1"),
      description: translate("careers-processDesc1"),
    },
    {
      count: "02",
      countPara: "Day 2-4",
      heading: translate("careers-processHeading2"),
      description: translate("careers-processDesc2"),
    },
    {
      count: "03",
      countPara: "Day 4-6",
      heading: translate("careers-processHeading3"),
      description: translate("careers-processDesc3"),
    },
    {
      count: "04",
      countPara: "Day 6-9",
      heading: translate("careers-processHeading4"),
      description: translate("careers-processDesc4"),
    },
    {
      count: "05",
      countPara: "Day 10",
      heading: translate("careers-processHeading5"),
      description: translate("careers-processDesc5"),
    },
  ],
};
