import React, { FC } from "react";
import * as styles from "./TwoColumnBanner.module.scss";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import Zoom from "react-medium-image-zoom";
import cx from "classnames";
import { ImageUrlCheck } from "../ImageUrlCheck/ImageUrlCheck";
import BlogCode from "../../BlogUiComponents/BlogPost/BlogCode";
import BlogTableLink from "../../BlogUiComponents/BlogPost/BlogTableLink";
import { BlogTable } from "../../BlogUiComponents/BlogsMain/BlogsMain.helper";
const BlockContent = require("@sanity/block-content-to-react");
type Props = {
  data: any;
  headingTop?: boolean;
  ourStory?: boolean;
  ourStorySecond?: boolean;
  style1?: string;
  style2?: string;
  order1?: number;
  order2?: number;
};
const TwoColumnBanner: FC<Props> = ({
  data,
  headingTop,
  ourStory,
  ourStorySecond,
  style1,
  style2,
  order1 = 1,
  order2 = 2,
}) => {
  const width = UseWindowSize();

  //@ts-ignore
  const serializers = {
    list: (props: { type: string; children: {} }) => {
      return props.type === "bullet" ? (
        <ul>{props.children}</ul>
      ) : (
        <ol>{props.children}</ol>
      );
    },
    listItem: (props: { type: string; children: {} }) => {
      return props.type === "bullet" ? (
        <li>{props.children}</li>
      ) : (
        <li>{props.children}</li>
      );
    },

    types: {
      block: (props: { node: { style?: "normal" }; children: {} }) => {
        const { style = "normal" } = props.node;

        if (/^h\d/.test(style)) {
          const level = style.replace(/[^\d]/g, "");
          return React.createElement(
            style,
            { className: `heading-${level}` },
            props.children
          );
        }

        return BlockContent.defaultSerializers.types.block(props);
      },

      code: (props: { node: { language: string; code: string } }) => (
        <BlogCode
          codeFormatter={"code-formatter"}
          language={props.node.language}
          code={props.node.code}
        />
      ),
      image: (props: { node: any }) => {
        return (
          <Zoom
            zoomMargin={150}
            overlayBgColorStart={"rgba(0, 0, 0)"}
            overlayBgColorEnd={"rgba(0, 0, 0)"}
          >
            <img
              src={ImageUrlCheck(props.node)}
              alt={props?.node?.alt || "Blog Content"}
            />
          </Zoom>
        );
      },
      table: (props: { node: { rows: any[] } }) => {
        return (
          //when having more time refactor the whole part with serializer
          <div className="scroll-bar-table">
            <table className={"table-custom"}>
              <tbody>
                {props.node.rows.map(
                  (tableElement: BlogTable, index1: number) => {
                    if (index1 === 0) {
                      return (
                        <tr key={index1}>
                          {tableElement.cells.map(
                            (tableCell: string, index2: number) => {
                              if (/<[a-z/][\s\S]*>/i.test(tableCell)) {
                                return (
                                  <th key={tableCell}>
                                    <BlogTableLink tableElement={tableCell} />
                                  </th>
                                );
                              } else {
                                return (
                                  <th key={tableCell + index2}>{tableCell}</th>
                                );
                              }
                            }
                          )}
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={index1}>
                          {tableElement.cells.map(
                            (tableCell: string, index2: number) => {
                              if (/<[a-z/][\s\S]*>/i.test(tableCell)) {
                                return (
                                  <td key={tableCell + index2}>
                                    <BlogTableLink tableElement={tableCell} />
                                  </td>
                                );
                              } else {
                                return (
                                  <td key={tableCell + index2}>{tableCell}</td>
                                );
                              }
                            }
                          )}
                        </tr>
                      );
                    }
                  }
                )}
              </tbody>
            </table>
          </div>
        );
      },
    },
    span: (props: any) => {
      return <span>{props.node.text}</span>;
    },
    marks: {
      //f you sanity
      link: ({ mark, children }: any) => {
        const { href } = mark;
        return (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      },
      strong: (props: any) => <strong>{props.children}</strong>,
      em: (props: any) => <em>{props.children}</em>,
      code: (props: any) => <code>{props.children}</code>,
    },
  };

  return (
    <InnerWrapper>
      <div className={styles.development}>
        <div
          className={
            ourStory || ourStorySecond ? styles.knowUsOurStory : styles.knowUs
          }
        >
          {headingTop ? (
            <div className={styles.knowUsHeading}>
              <h2 className={styles.head}>.{data?.heading}</h2>
            </div>
          ) : null}
          <div
            className={
              ourStory
                ? styles.ourStoryImg
                : ourStorySecond
                ? ""
                : cx(styles.knowUsImage, style1)
            }
            style={{
              order: order1,
            }}
          >
            <div
              className={
                ourStory || ourStorySecond
                  ? styles.ourStoryImgDiv
                  : styles.knowUsImageDiv
              }
            >
              <img
                src={
                  width.width <= 770
                    ? data?.img
                    : width.width <= 1200 && data?.mobileImg
                    ? data?.mobileImg
                    : data?.img
                }
                alt={data.alt}
                className={
                  ourStory && !ourStorySecond
                    ? styles.storyPromotePic
                    : ourStorySecond && ourStory
                    ? styles.storyPromotePicSecond
                    : styles.gdnPromotePic
                }
              />
              <CircleOutline
                isBlurVisible={true}
                initialScale={0.3}
                animateScale={1}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundCircleLeft}
              />
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.8}
                animateOpacity={0.2}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleLeft}
              />
            </div>
          </div>
          <div
            className={
              ourStory
                ? styles.knowUsOurStoryText
                : ourStorySecond
                ? ""
                : cx(styles.knowUsText, style2)
            }
            style={{
              order: order2,
              marginBottom: width.width < 770 ? "30px" : "",
            }}
          >
            <div>
              <h2
                className={
                  ourStory
                    ? styles.ourStoryText
                    : headingTop
                    ? styles.topHeading
                    : styles.bHeading
                }
              >
                .{data?.heading}
              </h2>
              <div
                className={
                  ourStory ? styles.ourStoryDescPara : styles.ourStoryDescPara
                }
              >
                <p className={ourStory ? styles.ourStoryDescPara : ""}>
                  {data?.details}{" "}
                </p>
                {data?.details2 && (
                  <p className={ourStory ? styles.ourStoryDescPara : ""}>
                    {data?.details2}
                  </p>
                )}

                <BlockContent
                  blocks={data?.description}
                  serializers={serializers}
                  projectId={"828ol6gw"} //see with david if you have all permisions, this needs to be in env not here and gatsby file
                  dataset={"production"}
                  useCdn={true}
                />
              </div>
            </div>

            {ourStory && !ourStorySecond ? (
              <>
                <CircleBlur
                  initialScale={0.1}
                  initialOpacity={0}
                  animateScale={1.8}
                  animateOpacity={0.2}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.aboutMainCircleBlurBlue}
                />
                <CircleOutline
                  isBlurVisible={true}
                  initialScale={0.3}
                  animateScale={1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundCircleLeftStory}
                />
                <CircleBlur
                  initialScale={0.1}
                  initialOpacity={0}
                  animateScale={1.8}
                  animateOpacity={0.2}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundInnerCircleLeft}
                />
              </>
            ) : ourStorySecond ? (
              <>
                <CircleBlur
                  initialScale={0.1}
                  initialOpacity={0}
                  animateScale={1.8}
                  animateOpacity={0.2}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundInnerCircleLeftSecond}
                />
                <CircleOutline
                  isBlurVisible={true}
                  initialScale={0.3}
                  animateScale={1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundCircleLeftStorySecond}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </InnerWrapper>
  );
};

export default TwoColumnBanner;
