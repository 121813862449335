// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "TwoColumnBanner-module--aboutMainCircleBlurBlue--DX9vc";
export var bHeading = "TwoColumnBanner-module--bHeading--fNiiM";
export var development = "TwoColumnBanner-module--development--oefMI";
export var gdnPromotePic = "TwoColumnBanner-module--gdnPromotePic--NDAnv";
export var head = "TwoColumnBanner-module--head--1AGA2";
export var heroBackgroundCircleLeft = "TwoColumnBanner-module--heroBackgroundCircleLeft--KyRzS";
export var heroBackgroundCircleLeftStory = "TwoColumnBanner-module--heroBackgroundCircleLeftStory--fpxdW";
export var heroBackgroundCircleLeftStorySecond = "TwoColumnBanner-module--heroBackgroundCircleLeftStorySecond--GtWlW";
export var heroBackgroundInnerCircleLeft = "TwoColumnBanner-module--heroBackgroundInnerCircleLeft--U0E+r";
export var heroBackgroundInnerCircleLeftSecond = "TwoColumnBanner-module--heroBackgroundInnerCircleLeftSecond--Vv36l";
export var knowUs = "TwoColumnBanner-module--knowUs--Edq+i";
export var knowUsHeading = "TwoColumnBanner-module--knowUsHeading--xI98x";
export var knowUsImage = "TwoColumnBanner-module--knowUsImage--wUbAR";
export var knowUsImageDiv = "TwoColumnBanner-module--knowUsImageDiv--crEsl";
export var knowUsOurStory = "TwoColumnBanner-module--knowUsOurStory--iZOMS";
export var knowUsOurStoryText = "TwoColumnBanner-module--knowUsOurStoryText--cbZZR";
export var knowUsText = "TwoColumnBanner-module--knowUsText--66eGS";
export var ourStoryDescPara = "TwoColumnBanner-module--ourStoryDescPara--NcSRY";
export var ourStoryImg = "TwoColumnBanner-module--ourStoryImg--PXvGt";
export var ourStoryImgDiv = "TwoColumnBanner-module--ourStoryImgDiv--fmPot";
export var ourStoryText = "TwoColumnBanner-module--ourStoryText--70uUw";
export var storyPromotePic = "TwoColumnBanner-module--storyPromotePic--lblUW";
export var storyPromotePicSecond = "TwoColumnBanner-module--storyPromotePicSecond--b+r7o";
export var topHeading = "TwoColumnBanner-module--topHeading--1C8yM";