import React, { FC } from "react";

type Props = {
  tableElement: string;
};

const BlogTableLink: FC<Props> = ({ tableElement }) => {
  const stringToHTML = function (str: string) {
    let dom = document.createElement("td");
    dom.innerHTML = str;
    return dom;
  };

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: stringToHTML(tableElement).innerHTML,
      }}
    />
  );
};

export default BlogTableLink;
